import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy
} from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { ValidationExecutionModel } from 'src/app/models/validation-execution.model';
import { ValidationStatusModel } from 'src/app/models/validation-status.model';
import { Subscription, concat } from 'rxjs';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { DocumentRenderService } from 'src/app/components/document-render/document-render.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { ValidationCollapsibleListComponent } from '../validation-collapsible-list.component';
import { LinkService } from 'src/app/services/link.service';
import { ChildrenOutletContexts, Router } from '@angular/router';
import { DocumentValidationService } from '../../document-validation.service';

@Component({
  selector: 'app-validation-collapsible-list-item',
  templateUrl: './validation-collapsible-list-item.component.html',
  styleUrls: ['./validation-collapsible-list-item.component.scss']
})
export class ValidationCollapsibleListItemComponent
  implements OnInit, OnDestroy
{
  @Input() item: any = {};
  @Input() elements: any = {};
  @Input() validationtypename: any;
  @Input() commentPerm: any;

  @Input() index: number;
  @Input() documentDetails: any;
  @Input() individualRules: any;
  @Input() groupRules: any;
  @Input() lineMarked: boolean;
  @Input() conditionMarked: boolean;
  @Output() discountFailure = new EventEmitter();
  @Output() updateTimeline = new EventEmitter();
  @Output() excludePreliminary = new EventEmitter();
  @Output() rerunPreliminary = new EventEmitter();
  @Output() lineMarkedChange = new EventEmitter();
  @Output() conditionMarkedChange = new EventEmitter();
  @Output() changePermComment = new EventEmitter();

  @Input() isFirstRow: boolean;

  public _documentStatus: any;
  public isCollapsed: boolean = true;
  public isOpen: boolean = false;
  public loadingCollapsible: boolean = false;
  public validationsStatus: ValidationStatusModel[];
  public isRerunDisabled: boolean = false;
  public isRerunDisabledTrigger: boolean;
  public isExcludeDisabled: boolean = false;

  public _validationTypes: any;
  public _validationStatus: any;
  public _userPermissions: any;
  public subscriptionUtils: Subscription;
  public markedChunks: Array<any> = [];
  public markedChunksSub: Subscription;
  public datamodels: any = [];
  public approval: any = {};
  public user: any = {};
  public isExternal: boolean = false;
  private reviewersGroupsTypes: any[];
  private subscriptionReviewers: Subscription;

  constructor(
    private global: GlobalService,
    private translate: TranslatePipe,
    private authService: AuthenticationService,
    private documentRenderService: DocumentRenderService,
    private listComponent: ValidationCollapsibleListComponent,
    private router: Router,
    private link: LinkService,
    private validationService: DocumentValidationService,
    private documentValidation: DocumentValidationService
  ) {
    this._documentStatus = this.global.getDocumentStatusConst();
    this._validationTypes = this.global.getValidationTypesConst();
    this._validationStatus = this.global.getValidationStatusConst();
    this._userPermissions = this.global.getUserPermissionsConst();
    this.user = authService.getLoggedInUser();
  }

  ngOnInit() {
    console.log(this.individualRules)
    console.log(this.groupRules)
    if (this.individualRules) {
      this.individualRules = [...this.individualRules.map(rule => ({
        ...rule,
        isCollapsed: true && rule.status
      }))];
    }
    
    if (this.groupRules) {
      this.groupRules = [...this.groupRules.map(rule => ({
        ...rule,
        isCollapsed: true && rule.status
      }))];

      this.groupRules = this.groupRules.map(rule => ({
        ...rule,
        fields: rule.fields ? rule.fields.split(',').map(field => field.trim()) : []
      }));
      
    }

    this.isRerunDisabledTrigger = this.setIsDisabledTrigger();
    this.validationsStatus = this.global.getValidationStatus();
    this.datamodels = Array.from(this.global.getDatamodels().values());
    /**
     * Initializing dropdowns
     */
    if (
      this.validationtypename === this._validationTypes.PRELIMINARY ||
      this.validationtypename === this._validationTypes.CATALOG
    ) {
      if (!this.item.validationexecutionid) {
        this.item['collapsed'] = true;
      }
    }
    if (this.item['collapsed'] == undefined) {
      this.item['collapsed'] = true;

      if (this.validationtypename === this._validationTypes.EXTRACTION) {
        this.validationService.setPreviousSearchExtraction(this.elements);
      }
      if (this.validationtypename === this._validationTypes.PRELIMINARY) {
        this.validationService.setPreviousSearchPreliminary(this.elements);
      }
      if (this.validationtypename === this._validationTypes.CATALOG) {
        this.validationService.setPreviousSearchCatalog(this.elements);
      }
      if (this.validationtypename === this._validationTypes.BUSINESS_RULES) {
        this.validationService.setPreviousSearchBusiness(this.elements);
      }
    }

    if (
      this.validationsStatus.length === 0 &&
      this.datamodels.length === 0 &&
      !this.global.passedWatcherDatamodels &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionUtils = concat(
        this.global.watchUtils(),
        this.global.watchDataModels()
      ).subscribe((data: string) => {
        this.validationsStatus = this.global.getValidationStatus();
        this.datamodels = Array.from(this.global.getDatamodels().values());
      });
    } else if (
      this.validationsStatus.length === 0 &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionUtils = this.global
        .watchUtils()
        .subscribe((data: string) => {
          this.validationsStatus = this.global.getValidationStatus();
        });
    } else if (
      this.datamodels.length === 0 &&
      !this.global.passedWatcherDatamodels
    ) {
      this.subscriptionUtils = this.global
        .watchDataModels()
        .subscribe((data: string) => {
          this.datamodels = Array.from(this.global.getDatamodels().values());
        });
    }
    if (
      this.isFirstRow &&
      (this.item.validationtypename === this._validationTypes.PRELIMINARY ||
        this.item.validationtypename === this._validationTypes.CATALOG)
    ) {
      this.toggleCollapse();
    }
    this.getReviewersTypes();
    this.markedChunksSub = this.documentRenderService
      .getMarkedChunks()
      .subscribe(({ chunks }) => {
        this.markedChunks = chunks;
      });
  }

  ngOnDestroy(): void {
    if (this.subscriptionUtils) {
      this.subscriptionUtils.unsubscribe();
    }
    if (this.markedChunksSub) {
      this.markedChunksSub.unsubscribe();
    }
    if (this.subscriptionReviewers) {
      this.subscriptionReviewers.unsubscribe();
    }
  }

  /**
   * Gets the reviewers group types from the global service and sets the approval according to them.
   */
  private getReviewersTypes() {
    this.reviewersGroupsTypes = this.global.getReviewers();
    if (
      this.reviewersGroupsTypes.length === 0 &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionReviewers = this.global.watchUtils().subscribe(() => {
        this.reviewersGroupsTypes = this.global.getReviewers();
        this.setApproval();
      });
    } else {
      this.setApproval();
    }
  }

  /**
   * Set approval and check if user is the assigned reviewer.
   */
  private setApproval() {
    this.approval = this.documentDetails.approvals.find(
      a => a.currentapproval === true
    );
  }

  /**
   * Check if user is the assigned reviewer.
   */
  public approvalIsAssignedToUser() {
    return (
      this.approval &&
      this.approval.assigned &&
      this.approval.assigned.userid === this.user.userid
    );
  }

  /**
   * Gets the title of the override button in the template.
   */
  public getOverrideButtonTitle() {
    if (!this.approvalIsAssignedToUser()) {
      return this.translate.transform('documentDetails.onlyAssigned');
    } else if (this.isExcludeDisabled) {
      return this.translate.transform(
        'validation.documentValidation.notAllowRerun'
      );
    } else {
      return this.translate.transform(
        'validation.documentValidation.excludePreliminary'
      );
    }
  }

  public setIsDisabledTrigger() {
    return (
      this.item.allowRerun &&
      this.item.validationmstrmultipledoc.some(
        md =>
          md.datamodeltrigger &&
          md.datamodelid !== this.documentDetails.datamodel.datamodelid
      )
    );
  }

  /**
   * TODO add documentation
   */
  public toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.item['collapsed'] = !this.item['collapsed'];
    if (this.item.children && this.item['collapsed'] == true) {
      this.item.children.forEach(element => {
        element['collapsed'] = true;
        if (element.conditions) {
          element.conditions.forEach(element => {
            element['collapsed'] = true;
          });
        }
      });
    }
    if (this.validationtypename === this._validationTypes.EXTRACTION) {
      this.validationService.setPreviousSearchExtraction(this.elements);
    }
    if (this.validationtypename === this._validationTypes.PRELIMINARY) {
      this.validationService.setPreviousSearchPreliminary(this.elements);
    }
    if (this.validationtypename === this._validationTypes.CATALOG) {
      this.validationService.setPreviousSearchCatalog(this.elements);
    }
    if (this.validationtypename === this._validationTypes.BUSINESS_RULES) {
      this.validationService.setPreviousSearchBusiness(this.elements);
    }
  }

  /**
   * TODO add documentation
   */
  public toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  /**
   * onClick event function: when clicks the top
   * of a level in collapsible list
   * @param item
   */
  public itemClick(item) {
    this.toggleCollapse();
    if (!item.children) {
      if (!item.message && !this.isOpen) {
        // If it is a multi doc validation, We have to ask for
        // the validation executions details
        this.loadingCollapsible = true;
        this.global
          .getValidationExecution(item.validationexecutionid, true)
          .then(
            (execution: ValidationExecutionModel) => {
              item.conditions = this.documentValidation.serializeConditions(
                execution,
                this.documentDetails,
                this.validationsStatus
              );
              item.islastversion = execution['islastversion'];
              this.toggleOpen();
              this.loadingCollapsible = false;
            },
            () => {
              this.loadingCollapsible = false;
            }
          );
      } else {
        this.toggleOpen();
      }
    }
  }

  /**
   * Send a signal to the father to
   * discount the number of failures
   */
  public onOverride() {
    this.item.message
      ? this.discountFailure.emit()
      : this.item.failureConditions--;
  }

  /**
   * Output function to re render the timeline
   * from the children components
   */
  public onUpdateTimeline() {
    this.updateTimeline.emit();
  }

  public onChangePermComment() {
    this.changePermComment.emit();
  }

  /**
   * Output function to rerun
   * preliminary execution
   * @param id
   */
  public onRerunPreliminary(id) {
    if (this.isRerunDisabled || this.isRerunDisabledTrigger) {
      return;
    }
    this.isRerunDisabled = true;
    this.rerunPreliminary.emit(id);
  }

  /**
   * Output function to rerun
   * preliminary execution
   * @param id
   */
  public onExcludePreliminary(id) {
    if (this.isExcludeDisabled) {
      return;
    }
    this.isExcludeDisabled = true;
    this.excludePreliminary.emit(id);
  }

  /**
   * Returns the name to show on the top of a level
   * If it has message item is the top of a condition's details list
   * If it has children, item is the top of executions list
   * Otherwise item is the top of conditions list
   * @param item
   */
  public getItemName(item) {
    let name: string;
    if (item.message) {
      name = item.validationexecutionconditionname
        ? item.validationexecutionconditionname
        : 'Condition ' + this.index;
    } else {
      if (item.children) {
        name =
          (item.validationtypename || item.label) + ' ' + item.validationname;
      } else {
        name = item.documentsNames;
        if (item.failureConditions > 0) {
          name +=
            ' (' +
            item.failureConditions +
            ' of ' +
            item.totalConditions +
            ' failed)';
        }
      }
    }
    return name;
  }

  public getLineColor(line, status) {
    line = line.trim();
    if (status === 'failure-not-applicable') {
      return status;
    } else if (line[0] === '-') {
      return 'failure';
    } else if (line[0] === '+') {
      return 'success';
    } else if (line[0] === '*') {
      return 'skipped';
    }
  }

  public formatLineOutput(line) {
    line = line.split('////')[0]; // Remove string after the special character
    line = line.trim();
    if (line[0] === '-' || line[0] === '+' || line[0] === '*') {
      line = this.formatSubline(line.substring(1));
    }

    return line;
  }

  public formatSubline(subline) {
    let sublines = subline.split('#*#');
    return sublines.join('\t - ');
  }

  public getPreliminaryRerunTitle() {
    if (this.isRerunDisabled) {
      return this.translate.transform(
        'validation.documentValidation.notAllowRerun'
      );
    } else if (this.isRerunDisabledTrigger) {
      const triggerDM = this.item.validationmstrmultipledoc.find(
        md => md.datamodeltrigger
      );
      const datamodelName = this.datamodels.find(
        dm => dm.datamodelid === triggerDM.datamodelid
      );
      const message = this.translate.transform(
        'validation.documentValidation.notAllowRerunTrigger'
      );
      return message + datamodelName ? datamodelName.datamodeldisplayname : '';
    } else {
      return this.translate.transform(
        'validation.documentValidation.reRunTests'
      );
    }
  }

  /**
   * Show current page chunks when hovering a condition
   * @param valExeCondition validation execution condition
   */
  public showConditionChunks(valExeCondition, line) {
    const row = line.row;
    if (
      line.message.trim()[0] === '*' ||
      this.markedChunks.length > 0 ||
      !valExeCondition.chunks ||
      this.documentRenderService.getDocumentId() !==
        +this.documentDetails.documentid
    ) {
      return;
    }
    this.documentRenderService.hideAllChunks();
    const chunksByDocuments = this.groupChunksByDoc(
      valExeCondition.chunks.filter(
        c => row !== null && c.validationrow === parseInt(row)
      )
    );
    const currentDocChunks = this.getCurrentDocumentChunks(chunksByDocuments);
    this.documentRenderService.updateDocumentChunks(
      currentDocChunks.map(c => ({ ...c, fromExtraction: false }))
    );
    const lineConditionChunks = [];
    currentDocChunks.forEach(c => {
      if (row !== null && c.validationrow === parseInt(row)) {
        lineConditionChunks.push(c.chunkid);
      }
    });
    if (currentDocChunks.length) {
      const fisrtChunk = currentDocChunks[0];
      this.documentRenderService.setScrollWithRatio(
        fisrtChunk.ypos,
        fisrtChunk.pagenumber
      );
    }
    this.documentRenderService.showChunks(lineConditionChunks);
  }

  /**
   * Hide chunks on document
   */
  public hideConditionChunks() {
    if (this.markedChunks.length > 0) {
      return;
    }
    this.documentRenderService.hideAllChunks();
  }

  /**
   * Mark condition chunks to allow navigate trought them
   * @param valExeCondition validation execution condition
   */
  public markLineConditionChunks(valExeCondition, line) {
    if (
      !valExeCondition.chunks ||
      this.documentRenderService.getDocumentId() !==
        +this.documentDetails.documentid
    ) {
      return;
    }
    this.listComponent.emptyAllChunks();
    this.item.messageLines.forEach(messageLine => {
      messageLine.lineMarked = false;
      messageLine.markedChunks = false;
    });
    line.markedChunks = true;
    this.lineMarked = true;
    this.lineMarkedChange.emit(true);
    this.documentRenderService.hideAllChunks();
    this.documentRenderService.emptyMarkedChunks();
    const chunksByDocuments = this.groupChunksByDoc(
      valExeCondition.chunks.filter(
        c => line.row !== null && c.validationrow === parseInt(line.row)
      )
    );
    const currentDocChunks = this.getCurrentDocumentChunks(chunksByDocuments);
    this.documentRenderService.updateDocumentChunks(
      currentDocChunks.map(c => ({ ...c, fromExtraction: false }))
    );
    if (currentDocChunks.length) {
      const fisrtChunk = currentDocChunks[0];
      this.documentRenderService.setScrollWithRatio(
        fisrtChunk.ypos,
        fisrtChunk.pagenumber
      );
    }
    this.documentRenderService.markChunksFromMultipleDocuments(
      chunksByDocuments
    );
    this.documentRenderService.goToFirstChunksPage(currentDocChunks);
  }

  /**
   * Mark condition chunks to allow navigate trought them
   * @param valExeCondition validation execution condition
   */
  public markConditionChunks(valExeCondition) {
    this.listComponent.emptyAllChunks();
    if (!valExeCondition.chunks) {
      return;
    }
    valExeCondition.markedChunks = true;
    this.conditionMarkedChange.emit(true);
    this.documentRenderService.hideAllChunks();
    this.documentRenderService.emptyMarkedChunks();
    const chunksByDocuments = this.groupChunksByDoc(valExeCondition.chunks);
    const currentDocChunks = this.getCurrentDocumentChunks(chunksByDocuments);
    this.documentRenderService.updateDocumentChunks(
      currentDocChunks.map(c => ({ ...c, fromExtraction: false }))
    );
    this.documentRenderService.updateDocumentChunks(currentDocChunks);
    this.documentRenderService.markChunksFromMultipleDocuments(
      chunksByDocuments
    );
    this.documentRenderService.goToFirstChunksPage(currentDocChunks);
  }

  private groupChunksByDoc(chunks) {
    const chunksByDocuments = chunks.reduce((acc, o) => {
      if (acc.hasOwnProperty(o.documentid)) {
        acc[o.documentid].push(o);
      } else {
        acc[o.documentid] = [o];
      }
      return acc;
    }, {});
    return chunksByDocuments;
  }

  private getCurrentDocumentChunks(chunks) {
    const currentDocChunks = chunks.hasOwnProperty(
      this.documentDetails.documentid
    )
      ? chunks[`${this.documentDetails.documentid}`].sort(
          (a, b) => a.ypos - b.ypos
        )
      : [];
    return currentDocChunks;
  }

  /**
   * Hide marked chunks
   */
  public hideMarkedChunks(valExeCondition) {
    this.item.messageLines.forEach(line => {
      if (line.markedChunks) {
        line.markedChunks = false;
      }
    });
    valExeCondition.markedChunks = false;
    this.conditionMarkedChange.emit(false);
    this.documentRenderService.emptyHighlightedChunks();
    this.documentRenderService.goToMainDoc(
      valExeCondition,
      this.documentRenderService.getDocumentId()
    );
  }

  /**
   * Hide line marked chunks
   */
  public hideMarkedLineChunks(valExeCondition, line) {
    line.markedChunks = false;
    this.lineMarked = false;
    this.lineMarkedChange.emit(false);
    this.documentRenderService.emptyHighlightedChunks();
    this.documentRenderService.hideAllChunks();
    this.documentRenderService.goToMainDoc(
      valExeCondition,
      this.documentRenderService.getDocumentId()
    );
  }

  public onLineMarked(event) {
    this.lineMarked = event;
    this.lineMarkedChange.emit(event);
  }

  public onConditionLineMarked(event) {
    this.conditionMarked = event;
    this.conditionMarkedChange.emit(event);
  }

  public getIcon(status: string) {
    if (status === undefined) {
      return '';
    }
    const replacedStatus = status.replace(/\s+/g, '-');
    if (
      [
        this._validationStatus.MANUAL_SUCCESS,
        this._validationStatus.SUCCESS
      ].includes(replacedStatus)
    ) {
      return 'check';
    } else if (
      [
        this._validationStatus.FAILURE_NA,
        this._validationStatus.FAILURE
      ].includes(replacedStatus)
    ) {
      return 'clear';
    } else if (replacedStatus === this._documentStatus.WARNING) {
      return 'warning';
    } else if (replacedStatus === this._validationStatus.SKIPPED) {
      return 'fiber_manual_record';
    } else if (replacedStatus === this._validationStatus.IN_PROGRESS) {
      return 'sync';
    }
  }

  public showPreliminaryDocumentWarning(item, validationtypename) {
    if (
      validationtypename === 'Preliminary' &&
      item.status === 'failure' &&
      item.conditionsstatus &&
      item.conditionsstatus.failure === 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  public toggleCollapseDetail(rule: any): void {
    rule.isCollapsed = !rule.isCollapsed;
  }
  
}


