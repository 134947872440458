import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaginationModel } from 'src/app/models/pagination';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { Router } from '@angular/router';
import { ValidationModel } from 'src/app/models/validation-mstr.model';
import { GlobalService } from 'src/app/services/global.service';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from 'src/app/components/modals/delete/delete.component';
import { Subscription, concat, Subject } from 'rxjs';
import { EventTrackerService } from 'src/app/services/event-tracker.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import * as DatamodelsConstants from 'src/app/constants/DatamodelsConstants';
import { DatamodelSearchService } from '../datamodel-search.service';
import { element } from 'protractor';
import { AssignChecklistComponent } from 'src/app/components/modals/assign-checklist/assign-checklist.component';
import { ImportDatamodelModalComponent } from 'src/app/components/modals/import-datamodels/import-datamodels.component';

@Component({
  selector: 'app-datamodel-validation-list',
  templateUrl: './datamodel-validation-list.component.html',
  styleUrls: ['./datamodel-validation-list.component.scss']
})
export class DatamodelValidationListComponent implements OnInit, OnDestroy {
  public user: any;
  private routeSub: Subscription;
  public tenantName: any;
  public pageSize: number = 10;
  public loading: boolean;
  public loaded: boolean = false;
  public backgroundLoading: boolean;
  private pagination: PaginationModel;
  public columns: any[];
  public settingTable: any;
  public rows: {};
  public showFilters: boolean = false;
  public showFiltersMulti: boolean = false;
  public showFiltersChecklist: boolean = false;
  public totalDatamodels = 0;
  public totalDatamodelsMulti = 0;
  public totalChecklist = 0;
  public filters: any = [];
  public labelFilters: any[];
  public labelFiltersMulti: any[];
  public labelFiltersChecklist: any[];
  public responseTableData = {};
  public items = [];
  public tabs = [];
  public currentTab: string;
  public showCheckList: boolean = false;
  public showValidation: boolean = false;
  public isValidUserData: boolean = false;
  public clickedRows: any[] = [];
  public disableexport: boolean = true;

  // MultipleDocs responsive table variables
  public loadingMultipleDocs: boolean;
  public backgroundLoadingMultipleDocs: boolean;
  public backgroundLoadingChecklist: boolean;

  private paginationMultipleDocs: PaginationModel;
  private paginationChecklist: PaginationModel;

  public columnsMultipleDocs: any[];
  public columnsCheckList: any[];
  public settingTableMultipleDocs: any;
  public settingTableChecklist: any;

  public rowsMultipleDocs: {};
  public rowsChecklist = {};

  public subscriptionDataModels: Subscription;
  public subscriptionUtils: Subscription;
  // Const
  public _userPermissions: any;
  public datamodelsConstants: any;
  public checklists = [];
  public loadingChecklist: boolean = true;
  public subscriptionChecklist: Subscription;

  //Property to emit filter to delete in the validations component
  public filterEvent: Subject<string> = new Subject<string>();

  constructor(
    private apiService: ApiService,
    public authService: AuthenticationService,
    private paginationService: PaginationService,
    private router: Router,
    private modalService: NgbModal,
    private global: GlobalService,
    private trackerService: EventTrackerService,
    private translate: TranslatePipe,
    private datamodelSearchService: DatamodelSearchService,
    
  ) {
    this.user = authService.getLoggedInUser();
    this._userPermissions = this.global.getUserPermissionsConst();
    this.tenantName = this.authService.getTenantName();

    this.getColumns();
    this.getSettingTable();

    this.getColumnsMultipleDocs();
    this.getSettingTableMultipleDocs();

    this.getChecklistsData();
    this.getColumnsChecklist();
    this.getSettingTableChecklist();
  }

  ngOnInit() {
    this.datamodelSearchService.cleanFilters();
    this.datamodelsConstants = this.global.getDatamodelsConst();

    this.showValidation = this.authService.userCanViewModule(
      this.user,
      'ValidationsCoreFunctionality'
    );

    this.showCheckList = this.authService.userCanViewModule(
      this.user,
      'CheckListFunctionality'
    );

    this.isValidUserData = this.authService.isValidUserData(this.user);
    
    if (this.showValidation) {
      this.tabs.push(this.datamodelsConstants.DATAMODEL_VALIDATIONS);
      // this.tabs.push(this.datamodelsConstants.EXTRACTION_BUSINESSRULES);
      // this.tabs.push(this.datamodelsConstants.PRELIMINARY_CATALOG);
    } else this.tabs.push(this.datamodelsConstants.DATAMODELLIST);

    // if (this.showCheckList && this.showValidation) {
    //   this.tabs.push(this.datamodelsConstants.CHECKLIST);
    // }
    this.currentTab = this.tabs[0];
    this.pagination =
      this.paginationService.getDefaultPagination('validations');
    const datamodelMap = this.global.getDataModelMap();
    const errorsmapping = this.global.getErrorsMapping();

    if (
      datamodelMap.size === 0 &&
      !errorsmapping &&
      !this.global.passedWatcherDatamodels &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionDataModels = concat(
        this.global.watchDataModels(),
        this.global.watchUtils()
      ).subscribe((data: string) => {
        this.initDatamodelValidationList();
      });
    } else if (
      datamodelMap.size === 0 &&
      !this.global.passedWatcherDatamodels
    ) {
      this.subscriptionUtils = this.global
        .watchDataModels()
        .subscribe((data: string) => {
          this.initDatamodelValidationList();
        });
    } else if (!errorsmapping && !this.global.passedWatcherUtils) {
      this.subscriptionUtils = this.global
        .watchUtils()
        .subscribe((data: string) => {
          this.initDatamodelValidationList();
        });
    } else {
      this.initDatamodelValidationList();
    }
  }

  /**
   * Retrive all checklists
   */
  public getChecklistsData() {
    this.checklists = this.global.getChecklists();
    if (this.checklists.length === 0 && !this.global.passedWatcherUtils) {
      this.loadingChecklist = true;
      this.subscriptionChecklist = this.global.watchUtils().subscribe(() => {
        this.checklists = this.global.getChecklists();
        this.loadingChecklist = false;
      });
    } else {
      this.loadingChecklist = false;
    }
  }

  private initDatamodelValidationList() {
    this.getValidations();
    if (this.isValidUserData && this.showValidation) {
      this.pageSize = 5;
      this.paginationMultipleDocs = this.paginationService.getDefaultPagination(
        'validationsMultipleDocs'
      );
      this.getValidationsMultipleDocs();
    }
    if (this.isValidUserData && this.showValidation && this.showCheckList) {
      this.paginationChecklist =
        this.paginationService.getDefaultPagination('checklist');
      this.getChecklists();
    }
    this.loaded = true;
  }

  /**
   * Get datamodels from storage or database
   */
  private getValidations() {
    const validationsPage = this.paginationService.getPage('validations');
    const defaultPage =
      this.paginationService.getDefaultPagination('validations');
    if (
      validationsPage &&
      validationsPage.data.length &&
      validationsPage.page === 1 &&
      validationsPage.order_by === defaultPage.order_by &&
      validationsPage.desc === defaultPage.desc
    ) {
      // If datamodels are loaded, show them and hide spinner
      this.rows = validationsPage;
      this.totalDatamodels = validationsPage.total_elements;
      this.loading = false;
      this.backgroundLoading = true;
    } else {
      // If datamodels aren't loaded, show spinner
      this.loading = true;
    }
    // Always request datamodels from db
    let params = this.getParams();
    this.getValidationsFromDB('number', params);
  }

  /**
   * Get checklists from storage or database
   */
  private getChecklists() {
    const validationsPage = this.paginationService.getPage('checklist');
    const defaultPage =
      this.paginationService.getDefaultPagination('checklist');
    this.loadingChecklist = true;
    let params = this.getParams();
    this.getChecklistsFromDB('number', params);
  }

  /**
   * Get datamodels from storage or database
   */
  private getValidationsMultipleDocs() {
    const MultipleDocsPage = this.paginationService.getPage(
      'validationsMultipleDocs'
    );
    const defaultPage = this.paginationService.getDefaultPagination(
      'validationsMultipleDocs'
    );
    if (
      MultipleDocsPage &&
      MultipleDocsPage.data.length &&
      MultipleDocsPage.page === 1 &&
      MultipleDocsPage.order_by === defaultPage.order_by &&
      MultipleDocsPage.desc === defaultPage.desc
    ) {
      // If datamodels are loaded, show them and hide spinner
      this.rowsMultipleDocs = MultipleDocsPage;
      this.loadingMultipleDocs = false;
      this.totalDatamodelsMulti = MultipleDocsPage.total_elements;
      this.backgroundLoadingMultipleDocs = true;
    } else {
      // If datamodels aren't loaded, show spinner
      this.loadingMultipleDocs = true;
    }
    // Always request datamodels from db
    let params = this.getParamsMulti();
    this.getValidationsMultipleDocsFromDB('number', params);
  }

  /**
   * Get preliminaries and catalogs validations from database
   */
  private getValidationsMultipleDocsFromDB(type = 'number', params) {
    const preliminaryValidations = this.global.getPreliminaryValidations();
    const dataToShowIni =
      this.paginationMultipleDocs.page * this.paginationMultipleDocs.page_size -
      this.paginationMultipleDocs.page_size; // 10
    const dataToShowFin =
      this.paginationMultipleDocs.page * this.paginationMultipleDocs.page_size; // 20

    let originalData = Array.from(preliminaryValidations.values()).sort(
      (a, b) => b.validationid - a.validationid
    );
    if (params) {
      originalData = this.filterValidationsMulti(originalData, params);
    }
    let num_pages = 1;
    if (this.paginationMultipleDocs.page_size <= originalData.length) {
      num_pages = Math.floor(
        originalData.length / this.paginationMultipleDocs.page_size
      );
      if (originalData.length % this.paginationMultipleDocs.page_size > 0) {
        num_pages = num_pages + 1;
      }
    }

    originalData.forEach(element => {
      if (element.catalog.createdbyuser) {
        element['catalog_modifiedbyuser'] =
          element.catalog.modifiedbyuser.username;
        element['catalog_modifieddate'] = element.catalog.modifieddate;
        element['catalog_version'] = element.catalog.version;
        element['catalog_validationname'] = element.catalog.validationname;
      } else {
        element['catalog_modifiedbyuser'] = '';
        element['catalog_validationname'] = '';
        element['catalog_modifieddate'] = '';
        element['catalog_version'] = '';
      }
      element['preliminary_modifiedbyuser'] = element.modifiedbyuser
        ? element.modifiedbyuser.username
        : '';
    });

    if (this.paginationMultipleDocs.order_by !== '') {
      if (
        this.paginationMultipleDocs.order_by === 'catalog_modifieddate' ||
        this.paginationMultipleDocs.order_by === 'modifieddate'
      ) {
        type = 'date';
      } else if (
        this.paginationMultipleDocs.order_by === 'preliminary_modifiedbyuser' ||
        this.paginationMultipleDocs.order_by === 'catalog_modifiedbyuser'
      ) {
        type = 'string';
      } else if (
        this.paginationMultipleDocs.order_by === 'catalog_version' ||
        this.paginationMultipleDocs.order_by === 'version'
      ) {
        type = 'number';
      }
      originalData = this.sortFunctions(
        originalData,
        type,
        this.paginationMultipleDocs.desc,
        this.paginationMultipleDocs.order_by
      );
    }

    const data = originalData.slice(dataToShowIni, dataToShowFin);

    const preliminaryPagination = {
      total_elements: originalData.length,
      page: this.paginationMultipleDocs.page,
      page_size: this.paginationMultipleDocs.page_size,
      num_pages: num_pages,
      order_by: this.paginationMultipleDocs.order_by,
      desc: this.paginationMultipleDocs.desc,
      data: data
    };

    this.rowsMultipleDocs = preliminaryPagination;
    this.paginationService.setPage(
      'validationsMultipleDocs',
      preliminaryPagination
    );
    this.paginationMultipleDocs = this.paginationService.setPagination(
      'validationsMultipleDocs',
      {
        total_elements: originalData.length,
        page: this.paginationMultipleDocs.page,
        page_size: this.paginationMultipleDocs.page_size,
        num_pages: preliminaryPagination.num_pages,
        order_by: this.paginationMultipleDocs.order_by,
        desc: this.paginationMultipleDocs.desc,
        query: this.paginationMultipleDocs.query
      }
    );
    this.paginationMultipleDocs = this.paginationService.getPagination(
      'validationsMultipleDocs'
    );
    this.totalDatamodelsMulti = originalData.length;
    this.loadingMultipleDocs = false;
    this.backgroundLoadingMultipleDocs = false;
  }

  /**
   * Get datamodels from database
   */
  private getChecklistsFromDB(type = 'number', params) {
    let data = JSON.parse(JSON.stringify(this.checklists));
    let originalData = JSON.parse(JSON.stringify(data));
    if (params) {
      data = this.filterChecklist(originalData, params);
      originalData = data;
    }

    data.map(checklist => {
      if (checklist.modifiedbyuser !== undefined) {
        checklist['modifiedbyuser'] = checklist.modifiedbyuser.username;
      } else {
        checklist['modifiedbyuser'] = '';
      }
    });
    const dataToShowIni =
      this.paginationChecklist.page * this.paginationChecklist.page_size -
      this.paginationChecklist.page_size;
    const dataToShowFin =
      this.paginationChecklist.page * this.paginationChecklist.page_size;

    if (this.paginationChecklist.order_by !== '') {
      if (this.paginationChecklist.order_by === 'createddate') {
        type = 'date';
      } else if (this.paginationChecklist.order_by === 'name') {
        type = 'string';
      } else {
        type = 'number';
      }
      originalData = this.sortFunctions(
        originalData,
        type,
        this.paginationChecklist.desc,
        this.paginationChecklist.order_by
      );
    }
    data = data.slice(dataToShowIni, dataToShowFin);
    this.rowsChecklist['total_elements'] = originalData.length;
    this.rowsChecklist['page'] = this.paginationChecklist.page;
    this.rowsChecklist['page_size'] = this.paginationChecklist.page_size;
    if (originalData.length <= data.length) {
      this.rowsChecklist['num_pages'] = Math.floor(
        originalData.length / this.paginationChecklist.page_size
      );
      if (originalData.length % this.paginationChecklist.page_size > 0) {
        this.rowsChecklist['num_pages'] = this.rowsChecklist['num_pages'] + 1;
      }
    } else {
      this.rowsChecklist['num_pages'] = 1;
    }
    this.rowsChecklist['order_by'] = this.paginationChecklist.order_by;
    this.rowsChecklist['desc'] = this.paginationChecklist.desc;
    this.rowsChecklist['data'] = data;
    this.paginationService.setPage('checklist', data);
    this.paginationChecklist = this.paginationService.setPagination(
      'checklist',
      {
        total_elements: data.length,
        page: this.rowsChecklist['page'],
        page_size: this.rowsChecklist['page_size'],
        num_pages: this.rowsChecklist['num_pages'],
        order_by: this.rowsChecklist['order_by'],
        desc: this.rowsChecklist['desc'],
        query: this.paginationChecklist.query
      }
    );
    this.paginationChecklist =
      this.paginationService.getPagination('checklist');

    this.totalChecklist = originalData.length;
    this.loadingChecklist = false;
    this.backgroundLoadingChecklist = false;
  }
  /**
   * Filter the datamodels with the params received
   */
  private filterValidations(data, params) {
    let invalidDatamodel = [];
    data.data.forEach((datamodel, index) => {
      if (params['datamodeldisplayname']) {
        if (
          !datamodel.datamodeldisplayname
            .toLowerCase()
            .includes(params['datamodeldisplayname'].toLowerCase())
        ) {
          invalidDatamodel.push(datamodel);
          return;
        }
      }
      if (params['createdByName']) {
        if (
          !datamodel.createdbyuser.username
            .toLowerCase()
            .includes(params['createdByName'].toLowerCase())
        ) {
          invalidDatamodel.push(datamodel);
          return;
        }
      }
      if (params['hasextraction']) {
        if (parseInt(params['hasextraction'])) {
          if (!datamodel.validations.extraction) {
            invalidDatamodel.push(datamodel);
            return;
          } else {
            if (!datamodel.validations.extraction.validationid) {
              invalidDatamodel.push(datamodel);
              return;
            }
          }
        } else {
          if (datamodel.validations.extraction) {
            if (datamodel.validations.extraction.validationid) {
              invalidDatamodel.push(datamodel);
              return;
            }
          }
        }
      }

      if (params['hasbusinessrules']) {
        if (parseInt(params['hasbusinessrules'])) {
          if (!datamodel.validations.business_rules) {
            invalidDatamodel.push(datamodel);
            return;
          } else {
            if (!datamodel.validations.business_rules.validationid) {
              invalidDatamodel.push(datamodel);
              return;
            }
          }
        } else {
          if (datamodel.validations.business_rules) {
            if (datamodel.validations.business_rules.validationid) {
              invalidDatamodel.push(datamodel);
              return;
            }
          }
        }
      }
    });
    data.data = data.data.filter(function (item) {
      return invalidDatamodel.indexOf(item) === -1;
    });
    data.total_elements = data.data.length;
  }

  /**
   * Filter the multiValidations with the params received
   */
  private filterValidationsMulti(data, params) {
    let invalidDatamodel = [];
    data.forEach((datamodel, index) => {
      if (params['catalogname']) {
        if (
          !datamodel.catalog_validationname
            .toLowerCase()
            .includes(params['catalogname'].toLowerCase())
        ) {
          invalidDatamodel.push(datamodel);
          return;
        }
      }
      if (params['preliminaryname']) {
        if (datamodel.validationname) {
          if (
            !datamodel.validationname
              .toLowerCase()
              .includes(params['preliminaryname'].toLowerCase())
          ) {
            invalidDatamodel.push(datamodel);
            return;
          }
        } else {
          invalidDatamodel.push(datamodel);
          return;
        }
      }
    });
    data = data.filter(function (item) {
      return invalidDatamodel.indexOf(item) === -1;
    });

    return data;
  }

  /**
   * Filter the checklist with the params received
   */
  private filterChecklist(data, params) {
    let invalidChecklist = [];
    data.forEach((checklist, index) => {
      if (params['checklistname']) {
        if (
          !checklist.name
            .toLowerCase()
            .includes(params['checklistname'].toLowerCase())
        ) {
          invalidChecklist.push(checklist);
          return;
        }
      }
      if (params['createdByName']) {
        if (
          !checklist.modifiedbyuser.username
            .toLowerCase()
            .includes(params['createdByName'].toLowerCase())
        ) {
          invalidChecklist.push(checklist);
          return;
        }
      }
    });
    data = data.filter(function (item) {
      return invalidChecklist.indexOf(item) === -1;
    });

    return data;
  }

  /**
   * Get datamodels from database
   */
  private getValidationsFromDB(type = 'number', params) {
    // TODO: deep copy object quick and dirty solution
    // const data = JSON.parse(JSON.stringify(this.global.getDataModelDataPagination()))
    // All data {....} {}
    const data = JSON.parse(
      JSON.stringify(this.global.getDataModelDataPagination())
    );
    if (params) {
      this.filterValidations(data, params);
    }
    data.data.map(datamodel => {
      if (!datamodel.validations.extraction) {
        datamodel.validations.extraction = this.serializeDataCreate();
      }

      datamodel['extraction_version'] =
        datamodel.validations.extraction.version !== undefined
          ? datamodel.validations.extraction.version
          : '';
      datamodel['extraction_modifieddate'] =
        datamodel.validations.extraction.modifieddate !== undefined
          ? datamodel.validations.extraction.modifieddate
          : '';
      if (datamodel.validations.extraction.modifiedbyuser !== undefined) {
        datamodel['extraction_modifiedbyuser'] =
          datamodel.validations.extraction.modifiedbyuser.username;
      } else {
        datamodel['extraction_modifiedbyuser'] = '';
      }

      if (!datamodel.validations.business_rules) {
        datamodel.validations.business_rules = this.serializeDataCreate();
      }

      datamodel['business_rules_version'] =
        datamodel.validations.business_rules.version !== undefined
          ? datamodel.validations.business_rules.version
          : '';
      datamodel['business_rules_modifieddate'] =
        datamodel.validations.business_rules.modifieddate !== undefined
          ? datamodel.validations.business_rules.modifieddate
          : '';
      if (datamodel.validations.business_rules.modifiedbyuser !== undefined) {
        datamodel['business_rules_modifiedbyuser'] =
          datamodel.validations.business_rules.modifiedbyuser.username;
      } else {
        datamodel['business_rules_modifiedbyuser'] = '';
      }
    });

    if (this.pagination.order_by !== '') {
      if (
        this.pagination.order_by === 'createddate' ||
        this.pagination.order_by === 'business_rules_modifieddate' ||
        this.pagination.order_by === 'extraction_modifieddate'
      ) {
        type = 'date';
      } else if (
        this.pagination.order_by === 'datamodeldisplayname' ||
        this.pagination.order_by === 'business_rules_modifiedbyuser' ||
        this.pagination.order_by === 'extraction_modifiedbyuser'
      ) {
        type = 'string';
      } else if (
        this.pagination.order_by === 'business_rules_version' ||
        this.pagination.order_by === 'extraction_version'
      ) {
        type = 'number';
      }
      data.data = this.sortFunctions(
        data.data,
        type,
        this.pagination.desc,
        this.pagination.order_by
      );
    }

    const originalData = JSON.parse(JSON.stringify(data));

    // 2
    const dataToShowIni =
      this.pagination.page * data.page_size - data.page_size;
    const dataToShowFin = this.pagination.page * data.page_size;

    data.data = data.data.slice(dataToShowIni, dataToShowFin);

    this.rows = data;

    // comment this beacuse if we use caché, we nned to change dara length in time
    // this.rows['total_elements'] = originalData.data.length;
    this.rows['page'] = this.pagination.page;
    this.rows['page_size'] = originalData.page_size;
    if (originalData.page_size <= data.data.length) {
      this.rows['num_pages'] = Math.floor(
        data.data.length / originalData.page_size
      );
      if (data.data.length % originalData.page_size > 0) {
        this.rows['num_pages'] = this.rows['num_pages'] + 1;
      }
    } else {
      this.rows['num_pages'] = 1;
    }

    this.rows['order_by'] = this.pagination.order_by;
    this.rows['desc'] = this.pagination.desc;

    this.paginationService.setPage('validations', data);
    this.pagination = this.paginationService.setPagination('validations', {
      total_elements: this.rows['total_elements'],
      page: this.rows['page'],
      page_size: this.rows['page_size'],
      num_pages: this.rows['num_pages'],
      order_by: this.rows['order_by'],
      desc: this.rows['desc'],
      query: this.pagination.query
    });
    this.totalDatamodels = this.rows['total_elements'];
    this.pagination = this.paginationService.getPagination('validations');
    this.backgroundLoading = false;
    this.loading = false;
  }

  sortFunctions(data, type, desc, order_by) {
    if (type === 'string') {
      return data.sort((a, b) => {
        if (desc) {
          return b[order_by].localeCompare(a[order_by]);
        } else {
          return a[order_by].localeCompare(b[order_by]);
        }
      });
    } else if (type === 'date') {
      return data.sort(function (a, b) {
        const date_a = a[order_by] === '' ? new Date(1) : new Date(a[order_by]);
        const date_b = b[order_by] === '' ? new Date(1) : new Date(b[order_by]);
        if (date_b > date_a && desc) {
          return 1;
        }
        if (date_a > date_b && desc) {
          return -1;
        }
        if (date_a > date_b) {
          return 1;
        }
        if (date_b > date_a) {
          return -1;
        }

        return 0;
      });
    } else if (type === 'number') {
      return data.sort((a, b) => {
        const number_a = a[order_by] === '' ? -1 : a[order_by];
        const number_b = b[order_by] === '' ? -1 : b[order_by];
        if (desc) {
          return number_b - number_a;
        } else {
          return number_a - number_b;
        }
      });
    }
  }

  /**
   * Cretes empty validation for table rendering
   */
  public serializeDataCreate() {
    const newValidation = new ValidationModel(0, undefined, false, false);
    return newValidation;
  }

  /**
   * /**
   * Redirect to create new preliminary validation
   */
  public newValidationMultipleDocs() {
    this.router.navigate(
      [`${this.tenantName}/datamodels-validations/validations/new-validation`],
      { queryParams: { validationtype: '3' } }
    );
  }

  /**
   * Redirect to edit datamodel
   */
  public onEditDatamodel(datamodel) {
    this.router.navigate(
      [`${this.tenantName}/datamodels-validations/datamodels-details`],
      {
        queryParams: { datamodelid: datamodel.datamodelid }
      }
    );
  }

  /**
   * Redirect to checklist
   */
  public onCreateChecklist() {
    this.router.navigate([
      `${this.tenantName}/datamodels-validations/new-checklist`
    ]);
  }

  /**
   * Redirect to checklist
   */
  public onEditChecklist(checklist) {
    this.router.navigate(
      [`${this.tenantName}/datamodels-validations/checklist/edit`],
      {
        queryParams: { id: checklist.id }
      }
    );
  }
  /**
   * Return loaded columns with the information of each column of the table we want to show
   */
  public getColumns() {
    this.columns = [
      {
        header: 'datamodel.name',
        name: 'datamodeldisplayname',
        type: 'link',
        clickLink: this.onEditDatamodel.bind(this),
        orderBy: 'datamodeldisplayname',
        class: 'ellipsis-text big-size-column',
        title: 'datamodeldisplayname'
      },
      {
        header: 'datamodel.user',
        name: 'createdbyuser',
        second: 'username',
        class: 'ellipsis-text medium-size-column',
        title: 'createdbyuser'
      },
      {
        header: 'datamodel.date',
        name: 'createddate',
        type: 'aging-date',
        format: 'lll',
        orderBy: 'createddate',
        title: 'createddate'
      }
    ];

    if (
      this.user &&
      this.authService.userCanViewModule(
        this.user,
        'ValidationsCoreFunctionality'
      ) &&
      (this.authService.hasPermission([
        this._userPermissions.CREATE_VALIDATIONS
      ]) ||
        this.authService.hasPermission([
          this._userPermissions.EDIT_VALIDATIONS
        ]) ||
        this.user.isadmin)
    ) {
      const validationsModule = [
        {
          type: 'custom',
          header: 'validation.extraction',
          title: 'validation.extraction',
          namefunc: rowData => {
            if (
              rowData.validations.extraction.validationid &&
              (this.authService.hasPermission([
                this._userPermissions.EDIT_VALIDATIONS
              ]) ||
                this.user.isadmin)
            ) {
              return '<i class="fa fa-edit"></i>';
            } else if (
              !rowData.validations.extraction.validationid &&
              (this.authService.hasPermission([
                this._userPermissions.CREATE_VALIDATIONS
              ]) ||
                this.user.isadmin)
            ) {
              return '<i class="fa fa-plus"></i>';
            }
          },
          event: rowData => {
            this.router.navigate(
              [
                `${this.tenantName}/datamodels-validations/validations/new-validation`
              ],
              {
                queryParams: {
                  datamodelid: rowData.datamodelid,
                  validationtype: '1'
                }
              }
            );
          }
        },
        {
          header: 'datamodel.extraction_data',
          name: [
            'extraction_modifiedbyuser',
            'extraction_modifieddate',
            'extraction_version'
          ],
          headerNames: {
            extraction_modifiedbyuser: 'datamodel.modifiedby',
            extraction_modifieddate: 'datamodel.modifieddate',
            extraction_version: 'datamodel.version'
          },
          type: 'datamodel-data',
          headerClass: 'ellipsis-text big-size-column',
          format: 'll'
        },
        {
          type: 'custom',
          header: 'validation.business_rules',
          title: 'validation.business_rules',
          namefunc: rowData => {
            if (!rowData.validations.extraction.validationid) {
              return '';
            } else {
              if (
                rowData.validations.business_rules.validationid &&
                (this.authService.hasPermission([
                  this._userPermissions.EDIT_VALIDATIONS
                ]) ||
                  this.user.isadmin)
              ) {
                return '<i class="fa fa-edit"></i>';
              } else if (
                !rowData.validations.business_rules.validationid &&
                (this.authService.hasPermission([
                  this._userPermissions.CREATE_VALIDATIONS
                ]) ||
                  this.user.isadmin)
              ) {
                return '<i class="fa fa-plus"></i>';
              }
            }
          },
          event: rowData => {
            this.router.navigate(
              [
                `${this.tenantName}/datamodels-validations/validations/new-validation`
              ],
              {
                queryParams: {
                  datamodelid: rowData.datamodelid,
                  validationtype: '2'
                }
              }
            );
          }
        },
        {
          header: 'datamodel.business_data',
          title: 'validation.business_data',
          name: [
            'business_rules_modifiedbyuser',
            'business_rules_modifieddate',
            'business_rules_version'
          ],
          headerNames: {
            business_rules_modifiedbyuser: 'datamodel.modifiedby',
            business_rules_modifieddate: 'datamodel.modifieddate',
            business_rules_version: 'datamodel.version'
          },
          headerClass: 'ellipsis-text big-size-column',
          type: 'datamodel-data',
          format: 'll'
        }
      ];
      // this.columns.push(...validationsModule); 
      const newValidationsModule = [
        {
          type: 'custom',
          header: 'validation.validations',
          title: 'validation.validations',
          namefunc: rowData => {
            if (
              rowData.validations.extraction.validationid &&
              (this.authService.hasPermission([
                this._userPermissions.EDIT_VALIDATIONS
              ]) ||
                this.user.isadmin)
            ) {
              return '<i class="fa fa-edit"></i>';
            } else if (
              !rowData.validations.extraction.validationid &&
              (this.authService.hasPermission([
                this._userPermissions.CREATE_VALIDATIONS
              ]) ||
                this.user.isadmin)
            ) {
              return '<i class="fa fa-plus"></i>';
            }
          },
          event: rowData => {
            this.router.navigate(
              [
                `${this.tenantName}/datamodels-validations/validations/new-validation`
              ],
              {
                queryParams: {
                  datamodelid: rowData.datamodelid,
                  validationtype: '1'
                }
              }
            );
          }
        },
      ]
      this.columns.push(...newValidationsModule); 


    }
    if (
      this.authService.hasPermission([this._userPermissions.TRAIN_MODELS]) ||
      this.user.isadmin
    ) {
      this.columns.push({
        type: 'custom',
        header: 'datamodel.train.header',
        title: 'datamodel.train.header',
        class: 'align-center',
        namefunc: () => '<i class="fa fa-dot-circle-o"></i>',
        event: rowData => {
          this.apiService
            .get(
              'datamodels/train/',
              { datamodelid: rowData.datamodelid },
              'datamodel.train.start'
            )
            .subscribe();
        }
      });
    }
    this.columns.push({
      type: 'custom',
      header: this.translate.transform('exportDatamodel.export'),
      title: 'datamodel',
      class: 'align-center',
      namefunc: rowData =>
        rowData.isClicked
          ? '<i class="fa fa-check-square"></i>'
          : '<i class="fa fa-square-o"></i>',
      event: rowData => {
        rowData.isClicked = !rowData.isClicked; 
        if (rowData.isClicked) {
          this.clickedRows.push(rowData.datamodelid); 
        } else {
        const index = this.clickedRows.indexOf(rowData.datamodelid);
          if (index !== -1) {
            this.clickedRows.splice(index, 1);
          }
        }
        this.disableexport = this.clickedRows.length === 0; 

      }
    });
  }

  /**
   * Return loaded SettingTable with the general settings for the component respontable
   */
  public getSettingTable() {
    const params = this.getParams();
    this.settingTable = {
      dataId: 'datamodelid',
      hasEditPermissions: this.hasEditPermissions(),
      hasSelect: false,
      getDataFromDB: this.getValidationsFromDB.bind(this, 'number', params),
      actionsOnSelected: [],
      actionsOnResponsive: [],
      responsiveTitle: {
        label: 'datamodeldisplayname'
      },
      paginationClass: 'relative-pag'
    };
  }

  /**
   * Handle loading data status from the child
   */
  public onLoadingChange(bool) {
    this.loading = bool;
  }

  /**
   * Return loaded columns with the information of each column of the table we want to show
   */
  public getColumnsMultipleDocs() {
    this.columnsMultipleDocs = [
      {
        type: 'custom',
        header: 'validation.preliminary',
        title: 'validationname',
        namefunc: rowData => {
          if (
            this.authService.hasPermission([
              this._userPermissions.EDIT_VALIDATIONS
            ]) ||
            this.user.isadmin
          ) {
            return rowData.validationname + '<i class="fa fa-edit"></i>';
          }
        },
        event: rowData => {
          this.router.navigate(
            [`${this.tenantName}/datamodels-validations/validations/edit`],
            {
              queryParams: {
                id: rowData.validationid,
                validationtype: '3'
              }
            }
          );
        }
      },
      {
        header: 'datamodel.preliminary_data',
        name: ['preliminary_modifiedbyuser', 'modifieddate', 'version'],
        headerNames: {
          preliminary_modifiedbyuser: 'datamodel.modifiedby',
          modifieddate: 'datamodel.modifieddate',
          version: 'datamodel.version'
        },
        headerClass: 'ellipsis-text big-size-column',
        type: 'datamodel-data',
        format: 'll'
      },
      {
        type: 'custom',
        header: 'validation.catalog',
        title: 'catalog_validationname',
        namefunc: rowData => {
          if (
            rowData.catalog.validationid &&
            (this.authService.hasPermission([
              this._userPermissions.EDIT_VALIDATIONS
            ]) ||
              this.user.isadmin)
          ) {
            return (
              rowData.catalog.validationname + '<i class="fa fa-edit"></i>'
            );
          } else if (
            !rowData.catalog.validationid &&
            (this.authService.hasPermission([
              this._userPermissions.CREATE_VALIDATIONS
            ]) ||
              this.user.isadmin)
          ) {
            return '<i class="fa fa-plus"></i>';
          }
        },
        event: rowData => {
          if (rowData.catalog.validationid) {
            this.router.navigate(
              [`${this.tenantName}/datamodels-validations/validations/edit`],
              {
                queryParams: {
                  id: rowData.catalog.validationid,
                  validationtype: '4'
                }
              }
            );
          } else {
            this.router.navigate(
              [
                `${this.tenantName}/datamodels-validations/validations/next-step`
              ],
              { queryParams: { id: rowData.validationid } }
            );
          }
        }
      },
      {
        header: 'datamodel.catalog_data',
        name: [
          'catalog_modifiedbyuser',
          'catalog_modifieddate',
          'catalog_version'
        ],
        headerNames: {
          catalog_modifiedbyuser: 'datamodel.modifiedby',
          catalog_modifieddate: 'datamodel.modifieddate',
          catalog_version: 'datamodel.version'
        },
        headerClass: 'ellipsis-text big-size-column',
        type: 'datamodel-data',
        format: 'll'
      },
      // {
      //   type: 'custom',
      //   header: 'checklist.assigned',
      //   title: 'checklist.assigned',
      //   namefunc: rowData => {
      //     if (rowData.catalog.validationid) {
      //       if (
      //         rowData.catalog.checklistid &&
      //         (this.authService.hasPermission([
      //           this._userPermissions.EDIT_VALIDATIONS
      //         ]) ||
      //           this.user.isadmin)
      //       ) {
      //         return (
      //           rowData.catalog.checklistid.name + '<i class="fa fa-edit"></i>'
      //         );
      //       } else if (
      //         !rowData.catalog.checklistid &&
      //         (this.authService.hasPermission([
      //           this._userPermissions.CREATE_VALIDATIONS
      //         ]) ||
      //           this.user.isadmin)
      //       ) {
      //         return '<i class="fa fa-plus"></i>';
      //       }
      //     }
      //   },
      //   event: rowData => {
      //     if (rowData.catalog.checklistId) {
      //     } else {
      //       this.assignToValidations(rowData);
      //     }
      //   }
      // }
    ];
  }

  /**
   * Return loaded columns with the information of each column of the table we want to show
   */
  public getColumnsChecklist() {
    this.columnsCheckList = [
      {
        type: 'custom',
        header: 'checklist.generalname',
        title: 'name',
        orderBy: 'name',
        namefunc: rowData => {
          if (
            this.authService.hasPermission([
              this._userPermissions.EDIT_VALIDATIONS
            ]) ||
            this.user.isadmin
          ) {
            return rowData.name + '<i class="fa fa-edit"></i>';
          }
        },
        event: rowData => {
          this.router.navigate(
            [`${this.tenantName}/datamodels-validations/checklist/edit`],
            {
              queryParams: {
                id: rowData.id
              }
            }
          );
        }
      },
      {
        header: 'datamodel.user',
        name: 'createdbyuser',
        second: 'username',
        class: 'ellipsis-text medium-size-column',
        title: 'createdbyuser'
      },
      {
        header: 'datamodel.date',
        name: 'createddate',
        type: 'aging-date',
        format: 'lll',
        orderBy: 'createddate',
        title: 'createddate'
      },
      {
        header: 'datamodel.modifiedby',
        name: ['modifiedbyuser', 'modifieddate'],
        headerNames: {
          modifiedbyuser: 'datamodel.modifiedby',
          modifieddate: 'datamodel.modifieddate'
        },
        type: 'datamodel-data',
        headerClass: 'ellipsis-text medium-size-column',
        format: 'll'
      }
    ];
  }

  /**
   * Return loaded SettingTable with the general settings for the component respontable
   */
  public getSettingTableMultipleDocs() {
    let params = this.getParamsMulti();
    this.settingTableMultipleDocs = {
      dataId: 'multipleDocs',
      hasEditPermissions: this.hasEditPermissions(),
      getDataFromDB: this.getValidationsMultipleDocsFromDB.bind(
        this,
        'number',
        params
      ),
      responsiveTitle: {
        label: 'validationname'
      },
      paginationClass: 'relative-pag'
    };
  }

  /**
   * Return loaded SettingTable with the general settings for the component respontable
   */
  public getSettingTableChecklist() {
    let params = this.getParams();
    this.settingTableChecklist = {
      dataId: 'checklist',
      hasEditPermissions: this.hasEditPermissions(),
      getDataFromDB: this.getChecklistsFromDB.bind(this, 'number', params),
      responsiveTitle: {
        label: 'validationname'
      },
      paginationClass: 'relative-pag'
    };
  }

  /**
   * Returns wether it should render checkbox or not
   * depending of status of row
   * @param row row to check its status
   */
  public shouldDisable(row) {
    let shouldDisable = false;
    this.items.forEach(element => {
      shouldDisable = true;
      if (element.id == row.id) {
        shouldDisable = false;
      }
    });

    return shouldDisable;
  }

  public getItemsToAssign() {
    this.items = [];
    for (const number_page of Object.keys(this.responseTableData)) {
      for (const number_item of Object.keys(
        this.responseTableData[number_page]
      )) {
        this.items.push(this.responseTableData[number_page][number_item]);
      }
    }
    return this.items;
  }

  /**
   * Load modal to assign a checklist to validations
   */
  public assignToValidations(validation) {
    console.log(validation);
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg',
      windowClass: 'modal--large'
    };
    const options = {
      type: 'new',
      action: 'create',
      validationid: validation.catalog.validationid,
      validationname: validation.catalog.validationname,
      checklists: this.checklists,
      assigned: validation.catalog.checklistid
    };
    const modalWindowRef = this.modalService.open(
      AssignChecklistComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(result => {
      if (result) {
        if (result.assignedchecklist) {
          const params = {
            validationid: validation.catalog.validationid,
            checklistid: result.assignedchecklist.id,
            datamodel_list: validation.catalog.validations,
            autoexecute: validation.catalog.validationautoexecute
          };

          this.onBackgroundLoadingChange(true);
          this.apiService
            .put(
              `validations/${validation.catalog.validationid}/assignto`,
              '',
              JSON.stringify(params),
              'checklist.assign'
            )
            .subscribe(() => {});
        } else if (result.unassignedchecklist) {
          const params = {
            validationid: validation.catalog.validationid,
            datamodel_list: validation.catalog.validations,
            autoexecute: validation.catalog.validationautoexecute
          };

          this.onBackgroundLoadingChange(true);
          this.apiService
            .put(
              `validations/${validation.catalog.validationid}/unassignto`,
              '',
              JSON.stringify(params),
              'checklist.unassign'
            )
            .subscribe(() => {});
        }
      }
    });
  }
  /**
   * Check if is selected when paginate to tick checkbox or not
   * @param index index of element
   * @param data all data array of current page
   */
  public isSelected(index, data) {
    const index_page = data.data.indexOf(index);
    let isSelected = false;
    if (this.responseTableData[data.page]) {
      if (this.responseTableData[data.page][index_page]) {
        isSelected = true;
      }
    }
    return isSelected;
  }

  /**
   * stored items selected in table to not lose it when paginate
   * @param index index of element
   * @param data all data array of current page
   */
  public changeSelectedItems(index, data) {
    let newAddition = false;
    if (data) {
      const index_page = data.data.indexOf(index);
      if (!this.responseTableData[data.page]) {
        this.responseTableData[data.page] = {};
      }
      if (this.responseTableData[data.page][index_page]) {
        delete this.responseTableData[data.page][index_page];
      } else {
        if (index_page >= 0) {
          newAddition = true;
          this.responseTableData[data.page][index_page] = data.data[index_page];
        }
      }
    }
    const items = [];

    for (const number_page of Object.keys(this.responseTableData)) {
      for (const number_item of Object.keys(
        this.responseTableData[number_page]
      )) {
        if (
          JSON.stringify(this.responseTableData[number_page][number_item]) ==
            JSON.stringify(index) &&
          !newAddition
        ) {
          delete this.responseTableData[number_page][number_item];
        } else {
          items.push(this.responseTableData[number_page][number_item]);
        }
      }
    }
    //this.countItemsSelected = items.length;
    //this.itemsSelected = items;
    return items;
  }
  /**
   * Handle loading data status from the child
   */
  public onLoadingChangeMultipleDocs(bool) {
    this.loadingMultipleDocs = bool;
  }

  /**
   * Handle loading data status from the child
   */
  public onLoadingChangeChecklist(bool) {
    this.loadingChecklist = bool;
  }

  /**
   * Check if user has edit permissions
   */
  private hasEditPermissions() {
    return this.user && this.user.isadmin;
  }

  /**
   * Get datamodels from storage or database
   */
  private getDatamodelsValidations() {
    const datamodelsPage = this.paginationService.getPage('datamodels');
    const defaultPage =
      this.paginationService.getDefaultPagination('datamodels');
    if (
      datamodelsPage &&
      datamodelsPage.data.length &&
      datamodelsPage.page === 1 &&
      datamodelsPage.order_by === defaultPage.order_by &&
      datamodelsPage.desc === defaultPage.desc
    ) {
      // If datamodels are loaded, show them and hide spinner
      this.rows = datamodelsPage;
      this.loading = false;
      this.backgroundLoading = true;
    } else {
      // If datamodels aren't loaded, show spinner
      this.loading = true;
    }
    // Always request datamodels from db
    let params = this.getParams();
    this.getValidationsFromDB('number', params);
  }

  /**
   * Delete single datamodel
   */
  private deleteDatamodel(item) {
    this.deleteDatamodelModal([item.datamodelid]);
  }

  /**
   * Delete multiple datamodels
   */
  private deleteSelectedDatamodels(selectedItems) {
    this.deleteDatamodelModal(selectedItems);
  }

  /**
   * Load modal to confirm datamodel(s) removal
   */
  private deleteDatamodelModal(datamodelsids: number[]) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const options = {
      type: 'datamodel',
      notAllowed: datamodelsids.length < 1
    };
    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(
      result => {
        if (result === 1) {
          this.loading = true;
          const requestbody = { ids: datamodelsids };
          this.sendDeleteRequest(requestbody);
        }
      },
      reason => {
        console.log('Reason ' + reason);
      }
    );
  }

  /**
   * Send delete request to API endpoint with payload
   */
  private sendDeleteRequest(requestbody: any) {
    this.apiService
      .post(
        'datamodels/delete',
        requestbody,
        'components.modal.delete.actionSuccess'
      )
      .subscribe(
        () => {
          this.pagination = this.paginationService.changePagination(
            'validations',
            'page',
            1
          );
          this.global.deleteDatamodels(requestbody['ids']);
          this.getDatamodelsValidations();
        },
        error => {
          // TODO: implement error message
        }
      );
  }

  ngOnDestroy(): void {
    if (this.subscriptionDataModels) {
      this.subscriptionDataModels.unsubscribe();
    }

    if (this.subscriptionUtils) {
      this.subscriptionUtils.unsubscribe();
    }

    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  public newTrainableDatamodel() {
    this.router.navigate([
      `${this.tenantName}/datamodels-validations/datamodels-details`
    ]);
  }

  /**
   * Variable to show the filter panel or not Validation
   */
  public toggleShowFilters() {
    this.showFilters = !this.showFilters;
  }

  /**
   * Variable to show the filter panel or not MultipleValidation
   */
  public toggleShowFiltersMulti() {
    this.showFiltersMulti = !this.showFiltersMulti;
  }

  /**
   * Variable to show the filter panel or not Checklist
   */
  public toggleShowFiltersChecklist() {
    this.showFiltersChecklist = !this.showFiltersChecklist;
  }

  /**
   * Search in the datamodelList with the filters applied
   * @param filters
   */
  public onSearchValidations(filters) {
    this.onBackgroundLoadingChange(true);
    this.filters = {
      createdByName:
        filters['createdByName'] !== undefined ? filters['createdByName'] : '',
      datamodeldisplayname:
        filters['datamodeldisplayname'] !== undefined
          ? filters['datamodeldisplayname']
          : '',
      hasextraction:
        filters['hasextraction'] !== undefined ? filters['hasextraction'] : '',
      hasbusinessrules:
        filters['hasbusinessrules'] !== undefined
          ? filters['hasbusinessrules']
          : ''
    };
    this.labelFilters = Object.keys(this.filters)
      .filter(k => this.filters[k] !== '')
      .map(f => {
        let value;
        switch (f) {
          case 'createdByName':
            value = this.filters[f];
            f = 'createdbynamedatamodel';
            break;
          case 'hasextraction':
            if (parseInt(this.filters[f])) {
              value = this.translate.transform('documentDetails.yes');
            } else {
              value = this.translate.transform('documentDetails.no');
            }
            break;
          case 'hasbusinessrules':
            if (parseInt(this.filters[f])) {
              value = this.translate.transform('documentDetails.yes');
            } else {
              value = this.translate.transform('documentDetails.no');
            }
            break;
          default:
            value = this.filters[f];
        }
        return { name: f, value };
      });
    let params = this.getParams();
    this.getSettingTable();
    this.pagination = this.paginationService.changePagination(
      'validations',
      'page',
      1
    );
    this.getValidationsFromDB('number', params);
  }

  /**
   * Search in the datamodelList with the filters applied
   * @param filters
   */
  public onSearchValidationsMulti(filters) {
    this.onBackgroundLoadingMultipleChange(true);
    this.filters = {
      catalogname:
        filters['catalogname'] !== undefined ? filters['catalogname'] : '',
      preliminaryname:
        filters['preliminaryname'] !== undefined
          ? filters['preliminaryname']
          : ''
    };
    this.labelFiltersMulti = Object.keys(this.filters)
      .filter(k => this.filters[k] !== '')
      .map(f => {
        let value;
        switch (f) {
          default:
            value = this.filters[f];
        }
        return { name: f, value };
      });
    let params = this.getParamsMulti();
    this.getSettingTableMultipleDocs();
    this.pagination = this.paginationService.changePagination(
      'validationsMultipleDocs',
      'page',
      1
    );
    this.getValidationsMultipleDocsFromDB('number', params);
  }

  /**
   * Search in the datamodelList with the filters applied
   * @param filters
   */
  public onSearchChecklist(filters) {
    this.onBackgroundLoadingChecklist(true);
    this.filters = {
      createdByName:
        filters['createdByName'] !== undefined ? filters['createdByName'] : '',
      checklistname:
        filters['checklistname'] !== undefined ? filters['checklistname'] : ''
    };
    this.labelFiltersChecklist = Object.keys(this.filters)
      .filter(k => this.filters[k] !== '')
      .map(f => {
        let value;
        switch (f) {
          default:
            value = this.filters[f];
        }
        return { name: f, value };
      });
    let params = this.getParams();
    this.getSettingTableChecklist();
    this.pagination = this.paginationService.changePagination(
      'checklist',
      'page',
      1
    );
    this.getChecklistsFromDB('number', params);
  }
  /**
   * Function to set the params validation to
   * pass to the API call depending
   */
  public getParams() {
    return {
      page_size: 30,
      page: 1,
      iseditable: '',
      ...this.filters
    };
  }

  /**
   * Function to set the params multi validation to
   * pass to the API call depending
   */
  public getParamsMulti() {
    return {
      ...this.paginationMultipleDocs,
      page_size: 5,
      ...this.filters
    };
  }

  /**
   * Handle background loading data status datamodel list
   */
  public onBackgroundLoadingChange(bool) {
    this.backgroundLoading = bool;
  }

  /**
   * Handle background loading data status multiple validtaions
   */
  public onBackgroundLoadingMultipleChange(bool) {
    this.backgroundLoadingMultipleDocs = bool;
  }

  /**
   * Handle background loading data status multiple validtaions
   */
  public onBackgroundLoadingChecklist(bool) {
    this.backgroundLoadingChecklist = bool;
  }
  /**
   * Toggles between tabs
   */
  public toggleTab(tab: string) {
    this.currentTab = tab;
  }

  public cleanIndividualFilter(filter: string){
    this.labelFilters = this.labelFilters.filter(f => f.name !== filter);
    
    if(filter === 'createdbynamedatamodel'){
      filter = 'createdByName'
    }

    this.filterEvent.next(filter)
    this.filters[filter] = '';
    this.onBackgroundLoadingChange(true);
    let params = this.getParams();
    this.getValidationsFromDB('number', params)
  }

  /**
   * Api service to export datamodels
   */  
  public exportDatamodels() {
    const datamodelsToExport = this.clickedRows;  
    datamodelsToExport.forEach(datamodelid => {
    const datamodeldisplayname = this.rows['data'].filter(datamodel => datamodel.datamodelid === datamodelid)[0].datamodeldisplayname;
      this.apiService
        .get('datamodels/export/', { pk: datamodelid }, `Datamodel: '${datamodeldisplayname}' successfully exported` )
        .subscribe(
          (data) => {
  
            const datamodelDisplayName = data.datamodel.datamodelDisplayName;
  
            const jsonData = JSON.stringify(data, null, 2);
  
            const blob = new Blob([jsonData], { type: 'application/json' });
  
            const url = window.URL.createObjectURL(blob);
  
            const a = document.createElement('a');
            a.href = url;
            a.download = `${datamodelDisplayName}.json`;
  
            document.body.appendChild(a);
            a.click();
  
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          },
          (error) => {
            console.error('Request error:', error);
          },
          () => {
            console.log('Datamodel exported');
          }
        );
    });
  }

  public openImportModal() {



    const modalRef = this.modalService.open(ImportDatamodelModalComponent, { windowClass: 'my-class'});
    modalRef.componentInstance.filesImported.subscribe((files: File[]) => {
      this.onImportDatamodels(files);
    });

  }

  onImportDatamodels(files: File[]) {
    files.forEach(file => console.log(file.name));
  }


  
  
  

}
