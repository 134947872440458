export const ID_EXTRACTION = 1;
export const ID_BUSINESS_RULES = 2;
export const ID_PRELIMINARY = 3;
export const ID_CATALOG = 4;
export const ID_VALIDATIONS = 5;

export const EXTRACTION = 'Extraction';
export const BUSINESS_RULES = 'Business Rules';
export const PRELIMINARY = 'Preliminary';
export const CATALOG = 'Catalog';
export const VALIDATIONS = 'Validations';